@import "../main/abstracts/index";
.knowledge{

    @include media-breakpoint-up(lg) {
        margin: 150px 0;
    }
    &__content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 -20px;
        flex-wrap: wrap;
        @include media-breakpoint-up(md) {
            flex-wrap: no-wrap;
        }
    }
    &__item{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;
        z-index: 1;
        position: relative;

        @include media-breakpoint-up(md) {
            width: 50%;
            padding: 20px;
        }
        @include media-breakpoint-up(lg) {
            width: 25%;
            padding: 20px;
        }
        &:before{
            content: "";
            position: absolute;
            left: -20px;
            top: 50%;
            height: 2px;
            width: 40px;
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba($color__primary,1) 50%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba($color__primary,1) 50%);
            background: linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba($color__primary,1) 50%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1c2e3a",GradientType=1);
            background-size: 200%;
            opacity: 0;
            @include media-breakpoint-up(lg) {
                opacity: 1;
            }
        }
        &:first-of-type{
            &:before{
                opacity: 0;
                @include media-breakpoint-up(lg) {
                    opacity: 1;
                    @each $breakpoint, $container-padding-size in $container-padding-sizes {
                        @include media-breakpoint-up(#{$breakpoint}) {
                            width: $container-padding-size + $container-padding-size;
                            left: calc(-1 * ($container-padding-size + $container-padding-size - 20px));
                        }
                    }
                    @media (min-width: #{map-get-last-value($container-max-widths)}) {
                        left: calc((((100vw - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)} + #{map-get-last-value($container-padding-sizes)} - 20px)* -1);
                        left: calc(((((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)} + #{map-get-last-value($container-padding-sizes)} - 20px) * -1);
                        width: calc(((100vw - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)} + #{map-get-last-value($container-padding-sizes)});
                        width: calc((((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)} + #{map-get-last-value($container-padding-sizes)});
                    }
                }
            }
        }
        &:last-of-type{


                &:after{
                    opacity: 0;
                @include media-breakpoint-up(lg) {
                    opacity: 1;
                    content: "";
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    height: 2px;
                    background: rgb(255,255,255);
                    background: -moz-linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba($color__primary,1) 50%);
                    background: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba($color__primary,1) 50%);
                    background: linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba($color__primary,1) 50%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1c2e3a",GradientType=1);
                    background-size: 200%;
                    background-position: 0%;
                    @each $breakpoint, $container-padding-size in $container-padding-sizes {
                        @include media-breakpoint-up(#{$breakpoint}) {
                            width: $container-padding-size + $container-padding-size;
                        }
                    }
                    @media (min-width: #{map-get-last-value($container-max-widths)}) {
                        width: calc(((100vw - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)} + #{map-get-last-value($container-padding-sizes)});
                        width: calc((((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)} + #{map-get-last-value($container-padding-sizes)});
                    }
                    transform: translateX(100%);
                }
            }
        }
        &--inner{
            background-color: $color__white;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .icon-container{
            margin-top: 55px;
            max-width: 100px;
            max-height: 100px;
            padding-bottom: 15px;
            transform: translateY(30px);
        }
    }
    &__count{
        color: $color__primary;
        @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 26px);
        font-weight: 600;
        margin: 0;
        opacity: 0;
        padding: 0;
        text-align: center;
        overflow: hidden;
    }
    &__description{
        padding-top: 15px;
        @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 18px);
        margin-bottom: 55px;
        padding-left: 25px;
        padding-right: 25px;
        text-align: center;
        transform: translateY(-30px);
    }
}

.knowledge__content.show .knowledge__item:first-of-type{
    &:before{
        animation: before 1s forwards;
    }
    .knowledge__count{
        animation: height 1s forwards;
        animation-delay: 1s;
    }
    .icon-container,
    .knowledge__description{
        animation: translate 1s forwards;
        animation-delay: 1s;
    }

}
.knowledge__content.show .knowledge__item:nth-of-type(2){
    &:before{
        animation: before 1s forwards;
        animation-delay: 2s;
    }
    .knowledge__count{
        animation: height 1s forwards;
        animation-delay: 3s;
    }
    .icon-container,
    .knowledge__description{
        animation: translate 1s forwards;
        animation-delay: 3s;
    }
}
.knowledge__content.show .knowledge__item:nth-of-type(3){
    &:before{

    animation: before 1s forwards;
    animation-delay: 4s;
    }
    .knowledge__count{
        animation: height 1s forwards;
        animation-delay: 5s;
    }
    .icon-container,
    .knowledge__description{
        animation: translate 1s forwards;
        animation-delay: 5s;
    }
}
.knowledge__content.show .knowledge__item:nth-of-type(4){
    &:before{
        animation: before 1s forwards;
        animation-delay: 6s;
    }
    .knowledge__count{
        animation: height 1s forwards;
        animation-delay: 7s;
    }
    .icon-container,
    .knowledge__description{
        animation: translate 1s forwards;
        animation-delay: 7s;
    }
    &:after{
        animation: before 1s forwards;
        animation-delay: 8s;
    }
}


@keyframes before {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: -100%;
    }
  }
  @keyframes height {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
  }
  @keyframes translate {
    100% {
        transform: translateY(0);
    }
  }
.section--knowledge-custom{
    position: relative;
    overflow: hidden;
    &:before{
        @include media-breakpoint-up(md) {
            width: 50vw;
        padding-top: 50vw;
        }
        max-width: 920px;
        max-height: 100%;
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100vw;
        padding-top: 100vw;
        background-image: url('/assets/website/img/logo/weil_der_stadt_tuerme.svg');
        background-size: cover;
        background-repeat: no-repeat;
    }
}

